import axios from 'axios'
import {
  Ordering,
  Portfolio,
  ProjectOption,
  ProjectTags,
  VintageOption,
} from 'src/interfaces/InterfaceGlobal'
import Cookie from 'js-cookie'
import { ct_vid_name } from 'src/static/models/cookies.names'
import Cookies from 'js-cookie'

export async function fetchCart() {
  const response = await axios.get('/api/v1/carts/')
  return response.data.results
}

export async function getProjectOptionsList(): Promise<ProjectOption[]> {
  const { data } = await axios.get<{ data: ProjectOption[] }>(
    '/api/v1/projects_options/get_all/'
  )
  return data?.data
}

export async function fetchCartNoLogged() {
  const ct_vid_cookie = Cookie.get(ct_vid_name)
  const response = await axios.get(`/api/v1/carts/?ct_vid=${ct_vid_cookie}`)
  return response.data.results
}
export async function fetchMarketplaceProfile(profile:string) {
  const response = await axios.get(`/api/v1/marketplace-profiles/${profile}/`)
  return response.data
}

export async function getProjects(order: Ordering, profile?: string) {
  const subdomain = window.location.host.split('.').reverse()[2]
  if(subdomain && subdomain !== 'market') {
    const { data: profiles } = await axios.get(`/api/v1/marketplace-profiles/`)
    profile = profiles.find(p=>p.subdomain===subdomain)?.code
    const exist = Cookies.get('ct-profile')
    if (exist) { Cookies.remove('ct-profile') }
    Cookies.set('ct-profile', profile as string, { expires: new Date(Date.now() + 2700000) })
  }
  const params = {
    page_size: 9999,
    is_visible_in_climatetrade: true,
    is_active: true,
    ordering: order,
    ct_vid: Cookie.get('ct_vid'),
  }
  if (profile) { params['profile_code'] = profile }
  const response = await axios.get('/api/v1/projects/', { params })
  return response.data
}

export async function getPortfolios():Promise<Portfolio[]> {
  const response = await axios.get(`/api/v1/portfolios/`)
  return response.data
}
export async function getSDGs() {
  const response = await axios.get(`/api/v1/sdg_compliance/?page_size=9999`)
  return response.data
}

export async function sendContactProject(
  content: string,
  subject: string,
  company: number
) {
  const response = await axios.post('/api/v1/messages/', {
    content: content,
    subject: subject,
    date: Date.now(),
    read: true,
    sender: 901,
    company: company,
  })
  return response.data
}

export async function fetchProjectVintageByAmount(
  id: number,
  amount: number,
  vintageId: number
) {
  const response = await axios.get(
    `/api/v1/final_price/?project_id=${id}&amount=${amount}&vintage_item_id=${vintageId}`
  )
  return response.data
}

export async function addCart(
  vintage: boolean,
  amount: number,
  projectCode: number,
  user: number,
  vintageItem?: VintageOption
) {
  if (vintage) {
    const response = await axios.post(`/api/v1/carts/`, {
      is_vintage: vintage,
      project_code: projectCode,
      user: user,
      vintage_items_order: [
        {
          amount: amount,
          vintage_item: vintageItem,
        },
      ],
    })
    return response
  } else {
    const response = await axios.post(`/api/v1/carts/`, {
      amount: amount,
      is_vintage: vintage,
      project_code: projectCode,
      user: user,
    })
    return response
  }
}

export async function getCountries() {
  const response = await axios.get(
    '/api/v1/countries/get_countries_with_projects/'
  )
  return response.data
}

export async function getCategories() {
  const response = await axios.get('/api/v1/projects/get_categories/')
  return response.data
}

export async function getTypesCategories() {
  const response = await axios.get('/api/v1/projects/by_category/')
  return response.data
}

export async function getPopularFilters() {
  const response = await axios.get('/api/v1/popular-filters/')
  return response?.data?.results || []
}
export const addFavorite = async (projectId: number, email: string) => {
  const response = await axios.post(`/api/v1/favourite_projects/`, {
    project: projectId,
    email: email,
  },{ params: {
    ct_vid: Cookie.get('ct_vid'),
  } })
  return response.data
}

export const removeFavorite = async (projectId: number) => {
  const response = await axios.delete(`/api/v1/favourite_projects/remove_favourite/`, { params: {
    project: projectId,
    ct_vid: Cookie.get('ct_vid'),
  } })
  return response.data
}
